import React, { useEffect, useState } from "react";
import GallerySection from "../../gallery/gallerySection";
import icon from "../../../assets/quote.png";
import ContactBanner from "./../../contactBanner/contactBanner";
import { getInteriorGalleryImages } from "../../../services/firebaseService";
import {
	Desrciption,
	HeaderContainer,
	Heading,
	VerticalSpacer,
} from "./interiorScreenStyle";
import GridView from "../../../componentsRepo/gridViewComponent/gridView";
import ListComponent from "../../../componentsRepo/listComponent/listComponent";

export default function InteriorScreen() {
	const [interiorGalleryImages, setInteriorGalleryImagesState] = useState([]);
	const setInteriorGalleryImages = async () => {
		const images = await getInteriorGalleryImages();
		setInteriorGalleryImagesState(images);
	};
	useEffect(() => {
		window.scrollTo(0, 0);
		setInteriorGalleryImages();
	}, []);
	const listDataColumns1 = [
		{
			title: "Chairs / Sofas",
			iconUrl: icon,
		},
		{
			title: "Living Rooms",
			iconUrl: icon,
		},
		{
			title: "Bedroom / Master Bedroom",
			iconUrl: icon,
		},
		{
			title: "Modular Kitchen",
			iconUrl: icon,
		},
		{
			title: "Wardrobes & Other Storages",
			iconUrl: icon,
		},
	];
	const listDataColumns2 = [
		{
			title: "Cabins / Conference Rooms",
			iconUrl: icon,
		},
		{
			title: "Cubicles & Partitioning",
			iconUrl: icon,
		},
		{
			title: "Tables & Storage Cabinets",
			iconUrl: icon,
		},
		{
			title: "Pantry Area",
			iconUrl: icon,
		},
		{
			title: "Executive Sofas & Chairs",
			iconUrl: icon,
		},
		{
			title: "Window Blinds",
			iconUrl: icon,
		},
	];
	const listDataColumns3 = [
		{
			title: "False Ceiling",
			iconUrl: icon,
		},
		{
			title: "All Types Of Flooring (Gym, Rubber, Wooden)",
			iconUrl: icon,
		},
		{
			title: "Wallpapers & Wall Panels",
			iconUrl: icon,
		},
		{
			title: "Wall to Wall Carpet (Galicha)",
			iconUrl: icon,
		},
		{
			title: "Interior & Exterior Paintings",
			iconUrl: icon,
		},
	];
	const itemObject = {
		fontSize: 18,
		iconColor: "yellow",
		iconBackground: "#e7ab25",
		iconRadius: "100%",
		fontWeight: "500",
		labelColor: "white",
		containerHeight: "24px",
		containerWidth: "24px",
		iconHeight: "12px",
		iconWidth: "12px",
		itemSpacing: "16px",
		itemMargin: "8px 0",
		blurBackground: "#e7ab2566",
	};
	const headerHeading = "COMMERCIAL & RESIDENTIAL INTERIOR DESIGN";
	const headerDescription =
		"Across different styles of interior design, we believe your interior reflects you.\nWe provide turnkey solutions in transforming your Commercial and Residential spaces.\nProviding an end-to-end solution including civil-work, furniture, electrical, plumbing and other interior elements & decor.";
	return (
		<React.Fragment>
			<HeaderContainer>
				<Heading>{headerHeading}</Heading>
				{headerDescription.split("\n").map((str) => (
					<Desrciption>{str}</Desrciption>
				))}
				<VerticalSpacer />
				<VerticalSpacer />
				<GridView
					desktopColumns={3}
					tabletColumns={2}
					phoneColumns={2}
					children={[
						<ListComponent margin={"28px 0"} listData={listDataColumns2} itemObject={itemObject} />,
						<ListComponent margin={"28px 0"} listData={listDataColumns1} itemObject={itemObject} />,
						<ListComponent margin={"28px 0"} listData={listDataColumns3} itemObject={itemObject} />,
					]}
				/>
				<VerticalSpacer />
				<VerticalSpacer />
				<Desrciption>Our furniture solutions include Modular, Factory-worked, Ready to assemble on site products for a hassle free experience.</Desrciption>
				<Desrciption>All kinds of fabrication & designing as per client design & specifications.</Desrciption>
				<VerticalSpacer />
				<VerticalSpacer />
				<Desrciption>We also deal in all kinds of interior products from renowned brands:</Desrciption>
				<Desrciption>Wall to Wall Carpets, Carpet Tiles, Rugs, Laminated Wooden Floorings, SPC (Stone PLastic Composite), Vinyl Floorings, Acrylic Grass, Blinds, Wallpapers, Curtains, Sofas, Bed Backs, Puffies, Cushion Chairs.</Desrciption>
			</HeaderContainer>
			<GallerySection data={interiorGalleryImages} />
			<ContactBanner title={"Bring a smile to your space"} />
		</React.Fragment>
	);
}

import styled from "styled-components";
import { Form } from "react-bootstrap";
export const FormContainer = styled(Form)`
	& {
		padding: 8px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
`;
export const SubmitButton = styled.div`
		width: fit-content;
		background:#e7ab25;
		padding:1% 4%;
		font-size:18px;
`;

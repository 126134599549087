import React from "react";
import {
	HorizontalDivider,
	StatContainer,
	StatDescription,
	StatTitle,
	StatParent,
	VerticalSpacer,
} from "./statsComponentStyle";


function Stat({ title, description,textColor }) {
	return (
		<StatContainer textColor={textColor}>
			<StatTitle>{title}</StatTitle>
			<StatDescription>{description}</StatDescription>
		</StatContainer>
	);
}

export default function StatsComponent() {
	return (
		<StatParent>
			<Stat title={30} description={"Years Of Work"} />
			<VerticalSpacer spacerHeight={14} />
			<HorizontalDivider />
			<VerticalSpacer spacerHeight={14} />
			<Stat title={"1K+"} description={"Projects"} textColor={"#E9B237"}/>
			<VerticalSpacer spacerHeight={14} />
			<HorizontalDivider />
			<VerticalSpacer spacerHeight={14} />
			<Stat title={"800+"} description={"Happy Clients"} />
			<VerticalSpacer spacerHeight={14} />
		</StatParent>
	);
}

import React from "react";
import FlexView from "./../../componentsRepo/flexViewComponent/flexView";
import ClientComponent from "./clientsComponent/clientComponent";
import cloverLogo from '../../assets/colver-logo-transparent.svg'
import dskLogo from '../../assets/dsk-logo-transparent.svg'
import ksbLogo from '../../assets/ksb-logo-transparent.svg'
import mcaLogo from '../../assets/mca-logo-transparent.png'
import psfLogo from '../../assets/poonawala-stud-farms-logo-transparent.png'
import geraLogo from '../../assets/gera-logo-transparent.svg'

export default function ClientsSection() {
	return (
		<div style={{background:"#F6EBE3",padding:"2% 0"}}>
			<FlexView
				child={[
					<ClientComponent width={"80%"} logo={cloverLogo} key={"client1"}/>,
					<ClientComponent width={"40%"} logo={dskLogo} key={"client2"}/>,
					<ClientComponent width={"60%"} logo={mcaLogo} key={"client3"}/>,
					<ClientComponent width={"40%"} logo={geraLogo} key={"client4"}/>,
					<ClientComponent width={"60%"} logo={ksbLogo} key={"client5"}/>,
					<ClientComponent width={"70%"} logo={psfLogo} key={"client6"}/>,
				]}
			/>
		</div>
	);
}

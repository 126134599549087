import React, { useEffect } from "react";
import {
	SideBarContainer,
	SideBarMenu,
	SideBarLink,
	IconButton,
	IconButtonText,
} from "./sideBarStyle";

export default function SideBar({ data, isOpen, toggle }) {
	useEffect(() => {
		document.body.style.overflow = isOpen ? "hidden" : "unset";
	});
	return (
		<>
			<SideBarContainer isOpen={isOpen}>
				<SideBarMenu>
					{data.NavBarData.map((item, index) => {
						return (
							<SideBarLink
								activeStyle={{
									textDecoration: "underline",
									textUnderlineOffset: "4px",
									textDecorationColor: "#E9B237",
									textDecorationThickness: "2px",
								}}
								to={item.path}
								onClick={toggle}
								key={item.title}
							>
								{item.title}
							</SideBarLink>
						);
					})}
					<IconButton to={"/contact"} onClick={toggle}>
						<IconButtonText>Contact Us</IconButtonText>
					</IconButton>
				</SideBarMenu>
			</SideBarContainer>
		</>
	);
}

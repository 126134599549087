import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CardComponent from "../../componentsRepo/contentCard/contentCard";
import FlexView from "../../componentsRepo/flexViewComponent/flexView";
import interiorService from "../../assets/InteriorDesignImage.svg";
import steelService from "../../assets/SteelStructuresImage.svg";
import solarService from "../../assets/SolarPanelsImage.svg";
import {
	CustomServiceContainer,
	ServiceImageContainer,
	ServiceImg,
	VerticalSpacer,
	ExtraService,
} from "./serviceSectionStyle";
const serviceOneObject = {
	cardPadding: "8% 0%",
	itemSpacing: "2%",
	bgColor: "white",
	alignItems: "flex-start",
	tagText: "COMMERCIAL & RESIDENTIAL INTERIOR DESIGN",
	tagTextSize: 18,
	tagTextColor: "#000000",
	tagFontWeight: "200",
	titleText:
		"We're a smart interior design service provider that makes your experience happy.",
	titleTextSize: 36,
	titleTextColor: "#000000",
	titleFontWeight: "900",
	descriptionText:
		"Across different styles of interior design, we believe your interior reflects you. \nWe provide turnkey solutions in transforming your Commercial and Residential spaces. Providing an end-to-end solution including civil-work, furniture, electrical, plumbing and other interior elements & decor.\nOur furniture solutions include Modular, Factory-worked, Ready to assemble on site products for a hassle free experience.",
	descriptionTextSize: 22,
	descriptionTextColor: "#000000",
	descriptionFontWeight: "200",
	buttonTextColor: "#000000",
	buttonText: "Learn More",
	buttonTextSize: 22,
	buttonFontWeight: "600",
	buttonFillColor: "#e7ab25",
	buttonRadius: "0",
	buttonPadding: "4px 12px",
	buttonBorderStyle: "3px solid #E9B237",
	desktopWeight: 3,
	tabletWeight: 2,
	phoneWeight: 1,
};
const serviceThreeObject = {
	cardPadding: "8% 0%",
	itemSpacing: "2%",
	bgColor: "white",
	alignItems: "flex-start",
	tagText: "SOLAR PANELING SOLUTIONS",
	tagTextSize: 18,
	tagTextColor: "#000000",
	tagFontWeight: "200",
	titleText: "Making y/our future shine",
	titleTextSize: 36,
	titleTextColor: "#000000",
	titleFontWeight: "700",
	descriptionText:
		'Solar power provides an efficient & economical "green" solution to the dependence on devastating fossil fuels. Amongst other renewable energy resources, solar power stands as the cleanest technologies available.\nWe provide solar power solutions for your residential, commercial & industrial buildings, housing societies, schools, hospitals, etc.',
	descriptionTextSize: 22,
	descriptionTextColor: "#000000",
	descriptionFontWeight: "200",
	buttonTextColor: "#000000",
	buttonText: "Learn More",
	buttonTextSize: 22,
	buttonFontWeight: "600",
	buttonFillColor: "#e7ab25",
	buttonRadius: "0",
	buttonPadding: "4px 12px",
	buttonBorderStyle: "3px solid #E9B237",
	desktopWeight: 3,
	tabletWeight: 2,
	phoneWeight: 1,
};
const serviceTwoObject = {
	cardPadding: "8% 0 4% 0",
	bgColor: "white",
	itemSpacing: "2%",
	alignItems: "flex-start",
	tagText: "UNIQUE ENGINEERINGS - STEEL FABRICATION & STRUCTURES",
	tagTextSize: 18,
	tagTextColor: "#000000",
	tagFontWeight: "200",
	titleText:
		"We design structures that are built to support and shape your ideas.",
	titleTextSize: 36,
	titleTextColor: "#000000",
	titleFontWeight: "700",
	descriptionText:
		"Our company is engaged in manufacturing of steel structures and all kind of fabrication works, as per client drawings and specifications. \nWith a fully equipped workshop and latest machinery, our engineers provide innovative and reliable solutions.",
	descriptionTextSize: 22,
	descriptionTextColor: "#000000",
	descriptionFontWeight: "200",
	buttonTextColor: "#000000",
	buttonText: "Learn More",
	buttonTextSize: 22,
	buttonFontWeight: "600",
	buttonFillColor: "#e7ab25",
	buttonRadius: "0",
	buttonPadding: "4px 12px",
	buttonBorderStyle: "3px solid #E9B237",
	desktopWeight: 3,
	tabletWeight: 2,
	phoneWeight: 2,
};
const customServiceDataOne = {
	cardPadding: "1% 2%",
	bgColor: "white",
	itemSpacing: "2%",
	alignItems: "flex-start",
	titleText: "01",
	titleTextSize: 36,
	titleTextColor: "#a4a4a4",
	titleFontWeight: "700",
	descriptionText:
		"Steel Structures\nIndustrial Sheds\nMezanine Floors\nPrefabricated Homes",
	descriptionTextSize: 16,
	descriptionTextColor: "#000000",
	descriptionFontWeight: "300",
	hoverStyle:
		"0 1px 4px rgba(236, 236, 236, 1), 0 0 18px rgba(236, 236, 236, 1)",
	desktopWeight: 5,
	tabletWeight: 3,
	phoneWeight: 3,
};
const customServiceDataTwo = {
	cardPadding: "1% 2%",
	bgColor: "white",
	itemSpacing: "2%",
	alignItems: "flex-start",
	titleText: "02",
	titleTextSize: 36,
	titleTextColor: "#a4a4a4",
	titleFontWeight: "700",
	descriptionText:
		"Collapsible / Compound Gates\nMetallic Home Doors\nSteel Windows\nAlluminium Sliding Windows",
	descriptionTextSize: 16,
	descriptionTextColor: "#000000",
	descriptionFontWeight: "300",
	hoverStyle:
		"0 1px 8px rgba(236, 236, 236, 1), 0 0 18px rgba(236, 236, 236, 1)",
	desktopWeight: 5,
	tabletWeight: 3,
	phoneWeight: 3,
};
const customServiceDataThree = {
	cardPadding: "1% 2%",
	bgColor: "white",
	itemSpacing: "2%",
	alignItems: "flex-start",
	titleText: "03",
	titleTextSize: 36,
	titleTextColor: "#a4a4a4",
	titleFontWeight: "700",
	descriptionText:
		"Steel Cupboards\nChairs\nCots\nWindow Grills\nStaircase & Railings",
	descriptionTextSize: 16,
	descriptionTextColor: "#000000",
	descriptionFontWeight: "300",
	hoverStyle:
		"0 1px 4px rgba(236, 236, 236, 1), 0 0 18px rgba(236, 236, 236, 1)",
	desktopWeight: 5,
	tabletWeight: 3,
	phoneWeight: 3,
};
const customServiceDataFour = {
	cardPadding: "1% 2%",
	bgColor: "white",
	itemSpacing: "2%",
	alignItems: "flex-start",
	titleText: "04",
	titleTextSize: 36,
	titleTextColor: "#a4a4a4",
	titleFontWeight: "700",
	descriptionText:
		"Industrial / Textile / Pharmaceutical Trolleys\nRolling Shutters\nMaterial Handling Systems",
	descriptionTextSize: 16,
	descriptionTextColor: "#000000",
	descriptionFontWeight: "300",
	hoverStyle:
		"0 1px 4px rgba(236, 236, 236, 1), 0 0 18px rgba(236, 236, 236, 1)",
	desktopWeight: 5,
	tabletWeight: 3,
	phoneWeight: 3,
};

function useWindowSize() {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}

function ServiceImage({ imageSrc, reverse }) {
	return (
		<ServiceImageContainer reverse={reverse}>
			<ServiceImg src={imageSrc} alt="" />
		</ServiceImageContainer>
	);
}
function CustomServiceCard({ onClick }) {
	const [width] = useWindowSize();
	return (
		<CustomServiceContainer>
			<CardComponent {...serviceTwoObject} onCallback={() => onClick()} />
			{width >= 1200 || width < 786 ? (
				<ExtraService>
					<CardComponent {...customServiceDataOne} />
					<CardComponent {...customServiceDataTwo} />
					<CardComponent {...customServiceDataThree} />
					<CardComponent {...customServiceDataFour} />
				</ExtraService>
			) : null}
		</CustomServiceContainer>
	);
}

export default function ServiceSection() {
	const history = useHistory();
	const interiorServiceClick = (path) => {
		history.push(path);
	};
	const [width] = useWindowSize();
	return (
		<React.Fragment>
			<VerticalSpacer height={"2%"} />
			<FlexView
				padding={"4% 6% 2% 6%"}
				reverse={true}
				child={[
					<ServiceImage
						imageSrc={interiorService}
						reverse={true}
						key={"interiorService"}
					/>,
					<CardComponent
						{...serviceOneObject}
						key={"interiorServiceImage"}
						onCallback={() => interiorServiceClick("/interior")}
					/>,
				]}
			/>
			<FlexView
				padding={"0% 6%"}
				reverse={false}
				child={[
					<ServiceImage
						imageSrc={steelService}
						reverse={false}
						key={"steelService"}
					/>,
					<CustomServiceCard
						onClick={() => interiorServiceClick("/steel")}
						key={"steelServiceImage"}
					/>,
				]}
			/>
			{width <= 1200 && width > 786 ? (
				<ExtraService padding={"0 8%"}>
					<CardComponent {...customServiceDataOne} />
					<CardComponent {...customServiceDataTwo} />
					<CardComponent {...customServiceDataThree} />
					<CardComponent {...customServiceDataFour} />
				</ExtraService>
			) : null}
			<FlexView
				padding={"2% 6% 8% 6%"}
				reverse={true}
				child={[
					<ServiceImage
						imageSrc={solarService}
						reverse={true}
						key={"solarService"}
					/>,
					<CardComponent
						{...serviceThreeObject}
						onCallback={() => interiorServiceClick("/solarpanel")}
						key={"solarServiceImage"}
					/>,
				]}
			/>
		</React.Fragment>
	);
}

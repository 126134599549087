import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo.svg";
import {
	NavBarContainer,
	NavbarLink,
	NavMenu,
	MenuToggleIcon,
	Logo,
	IconButton,
	IconButtonText,
} from "./navBarStyle";
import { FiMenu,FiX } from "react-icons/fi";

const NavBar = ({ links, toggle, isOpen, position }) => {
	const history = useHistory();
	const logoClick = (path) => {
		history.push(path);
	};
	return (
		<NavBarContainer bgColor={"#114246"} position={position}>
				<Logo onClick={()=>logoClick('/home')} src={logo} alt="logo" />
			<MenuToggleIcon>
				{!isOpen ? (
					<FiMenu className={"menuToggleIcon"} onClick={toggle} />
				) : (
					<FiX className="menuToggleIcon" onClick={toggle} />
				)}
			</MenuToggleIcon>
			<NavMenu>
				{links.NavBarData.map((item, index) => {
					return (
						<NavbarLink
							activeStyle={{
								textDecoration: "underline",
								textUnderlineOffset: "4px",
								textDecorationColor: "#E9B237",
								textDecorationThickness: "2px",
							}}
							to={item.path}
							key={index}
						>
							{item.title}
						</NavbarLink>
					);
				})}
				<IconButton to={"/contact"}>
					<IconButtonText>Contact Us</IconButtonText>
				</IconButton>
			</NavMenu>
		</NavBarContainer>
	);
};

export default NavBar;

import React from "react";
import GalleryCard from "./galleryCard/galleryCard";
import GalleryView from "./../../componentsRepo/galleryComponent/galleryComponent";
import { Header } from './gallerySectionStyle';
export default function GallerySection({ data }) {
	const cardComponents = [];
	data.map((item, index) => {
		cardComponents.push(<GalleryCard item={item} id={index} />);
		return null;
	});
	return (
		<div>
			<Header>OUR WORKS</Header>
			<GalleryView data={data} cardComponents={cardComponents} />
		</div>
	);
}

import React, { useEffect, useState } from "react";
import BannerSection from "../../banner/bannerSection";
import ClientsSection from "../../clients/clientsSection";
import TestimonialSection from "../../testimonials/testimonialSection";
import AboutSection from "../../about/aboutSection";
import ServiceSection from "../../services/serviceSection";
import ContactBanner from "../../contactBanner/contactBanner";
import {getBannerImages, getTestimonials} from '../../../services/firebaseService'

export default function HomePage() {
	const [bannerImages, setBannerImagesState] = useState([]);
	const [testimonials, setTestimonialsState] = useState([]);
	const setBannerImages = async () => {
		const images  = await getBannerImages();
		setBannerImagesState(images);
	};
	const setTestimonials = async () => {
		const images  = await getTestimonials();
		setTestimonialsState(images);
	};
	useEffect(() => {
		setBannerImages();
		setTestimonials();
	}, []);
	return (
		<React.Fragment>
			<BannerSection data={bannerImages} />
			<AboutSection />
			<ServiceSection />
			<ClientsSection />
			<TestimonialSection data={testimonials} />
			<ContactBanner title={"Bring a smile to your space"}/>
			{/* <GridView
				desktopColumns={2}
				tabletColumns={1}
				phoneColumns={1}
				children={[<FormComponent key={1} />, <MapComponent key={2} />]}
			/> */}
		</React.Fragment>
	);
}

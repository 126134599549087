import { GalleryCarouselRoot, ButtonRight } from "./galleryCarouselStyle";
import { useState } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const ImageGalleryCarousel = ({
	children,
	showNavigationArrows,
	carouselMargin,
	fromSlide,
}) => {
	const [activeStep, setActiveStep] = useState(
		fromSlide !== undefined ? fromSlide : 0
	);
	const maxSteps = children.length;
	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStepChange = (step) => {
		if (typeof step === "number") {
			setActiveStep(step);
		}
	};
	return (
		<GalleryCarouselRoot margin={carouselMargin}>
			{showNavigationArrows ? (
				<ButtonRight
					left={`0`}
					onClick={handleBack}
					disabled={activeStep === 0}
				>
					<FiArrowLeft />
				</ButtonRight>
			) : null}

			<AutoPlaySwipeableViews
				index={activeStep}
				onChangeIndex={handleStepChange}
				enableMouseEvents
				interval={5000}
			>
				{children.map((child, index) => {
					return child;
				})}
			</AutoPlaySwipeableViews>
			{showNavigationArrows ? (
				<ButtonRight
					right={`0`}
					onClick={handleNext}
					disabled={activeStep === maxSteps - 1}
				>
					<FiArrowRight />
				</ButtonRight>
			) : null}
		</GalleryCarouselRoot>
	);
};

export default ImageGalleryCarousel;

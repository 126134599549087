import React from "react";
import { isStringValid } from "../../utils/utils";
import {
	Button,
	CardContainer,
	DescriptionContainer,
	ImageConatiner,
	TagContainer,
	TitleContainer,
	VerticalSpacer
} from "./contentCardStyle";

function CardComponent({
	cardPadding,
	bgColor,
	gradient,
	imageLink,
	alignItems,
	imageHeight,
	imageWidth,
	tagText,
	tagTextSize,
	tagTextColor,
	tagFontWeight,
	titleText,
	titleTextSize,
	titleTextColor,
	titleFontWeight,
	descriptionText,
	descriptionTextSize,
	descriptionTextColor,
	descriptionFontWeight,
	buttonTextColor,
	buttonTextSize,
	buttonFontWeight,
	buttonFillColor,
	buttonRadius,
	buttonBorderStyle,
	buttonPadding,
	buttonText,
	onCallback,
	desktopWeight, //Only when using flex view
	tabletWeight, //Only when using flex view
	phoneWeight,
	itemSpacing, //Only when using flex view
	hoverStyle,
	boxShadow,
	child,
}) {
	const onClick = () => {
		onCallback();
	};
	return (
		<React.Fragment>
			<CardContainer
				cardPadding={cardPadding}
				bgColor={bgColor}
				gradient={gradient}
				alignItems={alignItems}
				hoverStyle={hoverStyle}
				boxShadow={boxShadow}
				desktopWeight={100 / desktopWeight + "%"}
				tabletWeight={100 / tabletWeight + "%"}
				phoneWeight={100 / phoneWeight + "%"}
			>
				{isStringValid(imageLink) ? (
					<ImageConatiner
						imageHeight={imageHeight}
						imageWidth={imageWidth}
						src={imageLink}
					/>
				) : null}
				<VerticalSpacer spacerHeight={itemSpacing}/>
				{isStringValid(tagText) ? (
					<TagContainer
						tagTextSize={tagTextSize}
						tagTextColor={tagTextColor}
						tagFontWeight={tagFontWeight}
					>
						{tagText}
					</TagContainer>
				) : null}
				<VerticalSpacer spacerHeight={itemSpacing}/>
				{isStringValid(titleText) ? (
					<TitleContainer
						titleTextSize={titleTextSize}
						titleTextColor={titleTextColor}
						titleFontWeight={titleFontWeight}
					>
						{titleText}
					</TitleContainer>
				) : null}
				<VerticalSpacer spacerHeight={itemSpacing}/>
				{isStringValid(descriptionText)
					? descriptionText.split('\n').map((str) => (
							<DescriptionContainer
								descriptionTextSize={descriptionTextSize}
								descriptionTextColor={descriptionTextColor}
								descriptionFontWeight={descriptionFontWeight}
							>
								{str}
							</DescriptionContainer>
					  ))
					: null}
				<VerticalSpacer spacerHeight={itemSpacing}/>
				{isStringValid(buttonText) ? (
					<Button
						buttonTextColor={buttonTextColor}
						buttonTextSize={buttonTextSize}
						buttonFontWeight={buttonFontWeight}
						buttonFillColor={buttonFillColor}
						buttonRadius={buttonRadius}
						buttonBorderStyle={buttonBorderStyle}
						buttonPadding={buttonPadding}
						onClick={() => onClick()}
					>
						{buttonText}
					</Button>
				) : null}
			</CardContainer>
		</React.Fragment>
	);
}

export default CardComponent;

import React from "react";
import Carousel from "../../componentsRepo/carousel/carousel";
import Testimonials from "./testimonialComponent/testimonials";
import { CarouselParent, TestimonialParent } from "./testimonialSectionStyle";
export default function TestimonialSection({ data }) {
	const children = [];
	if (window.innerWidth > 768) {
		for (var i = 1; i < data.length; i += 2) {
			children.push(
				<TestimonialParent key={i}>
					<Testimonials item={data[i - 1]} key={i - 1} />
					<Testimonials item={data[i]} key={i} />
				</TestimonialParent>
			);
		}
	} else {
		data.map((item, index) => {
			children.push(<Testimonials item={item} key={index} />);
			return null;
		});
	}
	return (
		<CarouselParent>
			<Carousel
				children={children}
				showNavigationArrows={true}
				backgroundColor={"#ffffff00"}
				carouselIndicatorColor={"#E9B237"}
			/>
		</CarouselParent>
	);
}

import styled from "styled-components";

export const ListItemContainer = styled.div`
	display: flex;
	margin: ${({ itemMargin }) => (itemMargin ? itemMargin : "0px")};
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	font-size: ${({ fontSize }) => (fontSize ? fontSize + "px" : "12px")};
	font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "200")};
	color: ${({ labelColor }) => (labelColor ? labelColor : "black")};
	flex-basis: 100%;
	padding: 0 16px;
	@media screen and (max-width: 768px) {
		padding: 0 0;
		font-size: ${({ fontSize }) =>
			fontSize ? fontSize * 0.95 + "px" : "12px"};
	}
	@media screen and (max-width: 540px) {
		font-size: ${({ fontSize }) => (fontSize ? fontSize * 0.9 + "px" : "12px")};
	}
`;

export const IconContainer = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	background: ${({ blurBackground }) =>
		blurBackground ? blurBackground : "12px"};
	width: ${({ iconSize }) => (iconSize ? iconSize : "12px")};
	aspect-ratio: 1/1;
	border-radius: ${({ iconRadius }) => (iconRadius ? iconRadius : "12px")};
	height: ${({ containerHeight }) =>
		containerHeight ? containerHeight : "12px"};
	width: ${({ containerWidth }) => (containerWidth ? containerWidth : "12px")};
	@media screen and (max-width: 540px) {
		font-size: ${({ fontSize }) => (fontSize ? fontSize * 0.9 + "px" : "12px")};
	}
`;
export const ItemIcon = styled.div`
	height: ${({ iconHeight }) => (iconHeight ? iconHeight : "12px")};
	width: ${({ iconWidth }) => (iconWidth ? iconWidth : "12px")};
	position: absolute;
	border-radius: 1000px;
	background: ${({ iconBackground }) =>
		iconBackground ? iconBackground : "#e4e4e4"};
`;

export const LabelContainer = styled.div`
	text-align: left;
`;
export const Label = styled.div``;
export const HorizontalSpacer = styled.span`
	width: ${({ itemSpacing }) => (itemSpacing ? itemSpacing : "0px")};
`;

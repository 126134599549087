import styled from "styled-components";

export const ParentContainer = styled.div`
	 display:flex;
	 flex-wrap:wrap;
	 width:100%;
	 flex-direction:${({ reverse }) => (reverse ? "row-reverse" : "row")};
	 justify-content:center;
	 padding:${({ padding }) => (padding ? padding : "0")};
	 align-items:${({ alignItems }) => (alignItems ? alignItems : "center")};;
    ${'' /* display: grid;
	grid-template-columns: repeat(auto-fit, minmax(30%, 1fr)); */}

	@media screen and (max-width: 768px) {
	}
	@media screen and (max-width: 420px) {
		${"" /* flex-direction:column; */}
	}
`;

import React from "react";
import { ParentContainer } from "./flexViewStyle";

export default function FlexView({ alignItems,padding, reverse, child }) {
	return (
		<ParentContainer alignItems={alignItems} reverse={reverse} padding={padding}>
			{child.map((c) => {
				return c;
			})}
		</ParentContainer>
	);
}

import firebase from "../firebase";

const db = firebase.firestore();

export async function getTestimonials() {
	const testimonials = [];
	const data = db.collection("Testimonials");
	const response = await data.get();
	response.docs.forEach((item) => {
		testimonials.push(item.data());
	});
	return testimonials;
}
export async function getBannerImages() {
	const bannerImages = [];
	const data = db.collection("Banner");
	const response = await data.get();
	response.docs.forEach((item) => {
		bannerImages.push(item.data());
	});
	return bannerImages;
}
export async function getInteriorGalleryImages() {
	const getInteriorGalleryImages = [];
	const data = db.collection("InteriorGallery");
	const response = await data.get();
	response.docs.forEach((item) => {
		getInteriorGalleryImages.push(item.data());
	});
	return getInteriorGalleryImages;
}
export async function getSteelFabricationGalleryImages() {
	const steelGalleryImages = [];
	const data = db.collection("SteelGallery");
	const response = await data.get();
	response.docs.forEach((item) => {
		steelGalleryImages.push(item.data());
	});
	return steelGalleryImages;
}
export async function getSolarPanelGalleryImages() {
	const solarGalleryImages = [];
	const data = db.collection("SolarGallery");
	const response = await data.get();
	response.docs.forEach((item) => {
		solarGalleryImages.push(item.data());
	});
	return solarGalleryImages;
}
export async function getDeveloperDetails() {
	const developerData = [];
	const data = db.collection("DeveloperData");
	const response = await data.get();
	response.docs.forEach((item) => {
		developerData.push(item.data());
	});
	return developerData;
}
import styled from "styled-components";

export const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	${'' /* background:red; */}
	font-size: 42px;
	padding: 2% 0 2% 0;
	@media screen and (max-width: 768px) {
		padding: 2% 0 4% 0;
		font-size: 38px;
	}
	@media screen and (max-width: 540px) {
		padding: 4% 0 8% 0;
		font-size: 32px;
	}
`;

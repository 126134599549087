import styled from "styled-components";
export const FABRoot = styled.div`
	background-color: #ffffff22;
	border-radius: 48%;
	padding: 8px;
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	bottom: 32px;
	right: 32px;
	z-index: 800;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.6);
	@media screen and (max-width: 768px) {
		bottom: 28px;
		right: 28px;
		padding: 8px;
	}
`;
export const FABImg = styled.img`
	height: 48px;
	width: 48px;
	@media screen and (max-width: 768px) {
		height: 42px;
		width: 42px;
	}
`;

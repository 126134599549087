import React from "react";
import whatsapp from "../../assets/whatsapp.png";
import { FABImg, FABRoot } from "./floatingActionButtonStyle";

function FloatingActionButton() {
	const handleWhatsAppOpen = (url) => {
		const newWindow = window.open(url, "_blank", "noopener,noreferrer");
		if (newWindow) newWindow.opener = null;
	};
	const message = "Hello Sir";
	return (
		<>
			<FABRoot
				onClick={() =>
					handleWhatsAppOpen(
						`https://api.whatsapp.com/send?phone=919822492452&text=${message}`
					)
				}
				className="root"
			>
				<FABImg src={whatsapp} alt="whatsapp" className="img" />
			</FABRoot>
		</>
	);
}
export default FloatingActionButton;

import styled from "styled-components";

export const StatParent = styled.div`
	display: flex;
	background: #00000000;
	align-items: center;
	width: 100%;
	flex-basis: 40%;
	padding: 0 4%;
	@media screen and (max-width: 1024px) {
		flex-basis: 100%;
		padding: 4% 0;
		justify-content: space-evenly;
	}
	@media screen and (max-width: 768px) {
		flex-direction: row;
		background-image: linear-gradient(to right, #225d65, #0d3336);
		padding: 4% 4%;
	}
	@media screen and (max-width: 420px) {
		flex-direction: row;
		flex-basis: 100%;
		background-image: linear-gradient(to right, #225d65, #0d3336);
	}
`;
export const HorizontalDivider = styled.span`
	border: 0;
	border-left: 1px solid white;
	height:9vh;
`;

export const VerticalSpacer = styled.span`
	width: ${({ spacerHeight }) => (spacerHeight ? spacerHeight*2+"px" : "0px")};
	@media screen and (max-width: 768px) {
		width: ${({ spacerHeight }) => (spacerHeight ? spacerHeight+"px" : "0px")};
	}
`;
export const StatContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: ${({ textColor }) => (textColor ? textColor : "white")};
	text-align: center;
`;
export const StatTitle = styled.div`
	font-size: 42px;
	font-weight: 700;
	@media screen and (max-width: 768px) {
		font-size: 38px;
	}
	@media screen and (max-width: 540px) {
		font-size: 32px;
	}
	@media screen and (max-width: 420px) {
		font-size: 30px;
	}
`;
export const StatDescription = styled.div`
	font-size: 20px;
	@media screen and (max-width: 768px) {
		font-size: 20px;
	}
	@media screen and (max-width: 540px) {
		font-size: 18px;
	}
	@media screen and (max-width: 540px) {
		font-size: 16px;
	}
`;

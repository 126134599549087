import React from "react";
import ListItem from "./listItem/listItem";
import { ListParent,ListParentContiner } from "./listComponentStyle";

export default function ListComponent({ margin,listData, itemObject }) {
	return (
		<ListParent margin={margin}>
		<ListParentContiner>
			{listData.map((item, index) => {
				return (
					<ListItem
						{...itemObject}
						key={index}
						itemLabel={item.title}
						iconUrl={item.iconUrl}
					/>
				);
			})}
		</ListParentContiner>
		</ListParent>
	);
}

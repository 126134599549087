import styled from "styled-components";
export const ListParentContiner = styled.div`
`;
export const ListParent = styled.div`
	width: 100%;
	flex: 1;
	height: fit-content;
	display: flex;
	margin: 0;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	@media screen and (max-width: 768px) {
		margin: ${({ margin }) => (margin ? margin : "0")};
	}
	@media screen and (max-width: 540px) {
		margin: ${({ margin }) => (margin ? margin : "0")};
	}
`;

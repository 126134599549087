import React from "react";
import { useHistory } from "react-router-dom";
import CardComponent from "../../componentsRepo/contentCard/contentCard";
import FlexView from "../../componentsRepo/flexViewComponent/flexView";
import StatsComponent from "./statsComponent/statsComponent";

const aboutUsObject = {
	cardPadding: "4% 4% 4% 6%",
	itemSpacing:"2%",
	bgColor:"#00000000",
	alignItems: "flex-start",
	tagText: "ABOUT US",
	tagTextSize: 18,
	tagTextColor: "#ffffff",
	tagFontWeight: "200",
	titleText: "What We Can Do For You?",
	titleTextSize: 36,
	titleTextColor: "#ffffff",
	titleFontWeight: "900",
	descriptionText:
		"We are a client-oriented firm offering all our services in various customised options as per the varied requirements of our clients. We provide best solution as per your requirement with an Expert & Experienced team and trained work force.",
	descriptionTextSize: 22,
	descriptionTextColor: "#ffffff",
	descriptionFontWeight: "300",
	buttonTextColor: "#000000",
	buttonText: "Inquire Now",
	buttonTextSize: 22,
	buttonFontWeight: "600",
	buttonFillColor: "#e7ab25",
	buttonRadius: "0",
	buttonPadding: "4px 12px",
	buttonBorderStyle: "3px solid #E9B237",
	desktopWeight: 2,
	tabletWeight: 2,
	phoneWeight: 1,
};

export default function AboutSection() {
	const history = useHistory();
	const inquireNowButtonClick=(path)=>{
		history.push(path);
	}
	return (
		<div style={{backgroundImage:"linear-gradient(to right,#225d65, #0d3336)"}}>
		<FlexView
			child={[<CardComponent {...aboutUsObject} onCallback={()=>inquireNowButtonClick('/contact')} key={"about"}/>, <StatsComponent key={"stats"}/>]}
		/>
		</div>
	);
}

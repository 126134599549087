import { CardImage, GalleryCardContainer } from "./galleryCardStyle";
import React from "react";

export default function GalleryCard({ item, id }) {
	return (
		<GalleryCardContainer>
			<CardImage src={item.imageLink} />
		</GalleryCardContainer>
	);
}

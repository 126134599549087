import React, { useState } from "react";
import GalleryCarousel from "./carouselView/carouselView";
import Grid from "@mui/material/Grid";
import { GalleryGrid } from "./galleryComponentStyle";

function GalleryView({ data, cardComponents }) {
	const [show, setShow] = useState(false);
	const [carouselImageId, setcarouselImagelId] = useState(0);
	const onCardClick = (id) => {
		setShow(true);
		setcarouselImagelId(id);
		document.body.style.overflow = "hidden";
	};
	const onCarouselClose = () => {
		setShow(false);
		document.body.style.overflow = "unset";
	};
	const children = [];
	cardComponents.map((item, index) => {
		children.push(<div onClick={() => onCardClick(index)}>{item}</div>);
		return null;
	});
	return (
		<>
			{show ? (
				<div style={{ overflow: "hidden", position: "absolute" }}>
					<GalleryCarousel
						show={show}
						onCarouselClose={onCarouselClose}
						carouselImageId={carouselImageId}
						data={data}
					/>
				</div>
			) : null}
			<GalleryGrid>
				{children.map((value) => (
					<Grid key={value.id}>{value}</Grid>
				))}
			</GalleryGrid>
		</>
	);
}

export default GalleryView;

import React, { useState, useEffect } from "react";
import GallerySection from "../../gallery/gallerySection";
import ContactBanner from "../../contactBanner/contactBanner";
import { getSolarPanelGalleryImages } from "../../../services/firebaseService";
import { Desrciption, HeaderContainer, Heading, VerticalSpacer } from "./solarPanelStyle";
export default function SolarPanels() {
	const [solarPanelsGalleryImages, setSolarPanelsGalleryImagesState] = useState(
		[]
	);
	const setSolarPanelsGalleryImages = async () => {
		const images = await getSolarPanelGalleryImages();
		setSolarPanelsGalleryImagesState(images);
	};
	useEffect(() => {
		window.scrollTo(0, 0);
		setSolarPanelsGalleryImages();
	}, []);
	const headerHeading = "Solar Panelling Solutions";
	const headerDescription =
		'Solar power provides an efficient & economical "green" solution to the dependence on devastating fossil fuels. Amongst other renewable energy resources, solar power stands as the cleanest technologies available. We provide solar power solutions for your residential, commercial & industrial buildings, housing societies, schools, hospitals, etc.';
	return (
		<React.Fragment>
			<HeaderContainer>
				<Heading>{headerHeading}</Heading>
				<Desrciption>{headerDescription}</Desrciption>
				<VerticalSpacer />
			</HeaderContainer>
			<GallerySection data={solarPanelsGalleryImages} />
			<ContactBanner title={"Let us help you make your future shine!"} />
		</React.Fragment>
	);
}

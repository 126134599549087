import React from 'react';
import { ClientContainer,ClientLogo } from './clientComponentStyle';


export default function ClientComponent({width,logo}){    
    return(
        <ClientContainer>
            <ClientLogo width={width} src={logo}/>
        </ClientContainer>
    )
}
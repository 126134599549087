import React from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AppBar from "./componentsRepo/navBar/appBar";
import Footer from "./mainComponents/footer/footer";
import HomePage from "./mainComponents/screens/homepage/homepage";
import SolarPanels from "./mainComponents/screens/solarPanels/solarPanels";
import ContactUs from "./mainComponents/screens/contactUs/contactsUs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InteriorScreen from "./mainComponents/screens/interior/interiorScreen";
import SteelFabrications from "./mainComponents/screens/steelFabrications/steelFabrications";
import FloatingActionButton from './componentsRepo/floatingActionButton/floatingActionButton';

function App() {
	return (
		<div className="App">
			<ToastContainer toastClassName="color" />
			<AppBar
				position={useLocation().pathname === "/home" ? "static" : "static"}
			/>
			<Switch>
				<Route path="/home">
					<HomePage />
				</Route>
				<Route path="/interior">
					<InteriorScreen />
				</Route>
				<Route path="/steel">
					<SteelFabrications />
				</Route>
				<Route path="/solarpanel">
					<SolarPanels />
				</Route>
				<Route path="/contact">
					<ContactUs />
				</Route>
				<Redirect from="/" exact to="/home" />
			</Switch>
			<FloatingActionButton/>
			<Footer />
		</div>
	);
}

export default App;

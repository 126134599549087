import styled from "styled-components";

export const ClientContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-basis: 15%;
	@media screen and (max-width: 768px) {
		flex-basis: 30%;
		padding: 4% 0;
	}
	@media screen and (max-width: 560px) {
		padding: 4% 0;
		flex-basis: 40%;
	}
`;
export const ClientLogo = styled.img`
	display:block;
	align-self: center;
`;

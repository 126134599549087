import React, { useState, useEffect } from "react";
import GallerySection from "../../gallery/gallerySection";
import icon from "../../../assets/quote.png";
import ContactBanner from "../../contactBanner/contactBanner";
import { getSteelFabricationGalleryImages } from "../../../services/firebaseService";
import GridView from "./../../../componentsRepo/gridViewComponent/gridView";
import ListComponent from "./../../../componentsRepo/listComponent/listComponent";
import {
	Desrciption,
	HeaderContainer,
	Heading,
	VerticalSpacer,
} from "./steelFabricationStyle";

export default function SteelFabrications() {
	const [steelFabricationGalleryImages, setSteelFabricationGalleryImagesState] =
		useState([]);
	const setSteelFabricationGalleryImages = async () => {
		const images = await getSteelFabricationGalleryImages();
		setSteelFabricationGalleryImagesState(images);
	};
	useEffect(() => {
		window.scrollTo(0, 0);
		setSteelFabricationGalleryImages();
	}, []);
	const listDataColumn1 = [
		{
			title: "Steel Structures",
			iconUrl: icon,
		},
		{
			title: "Industrial Sheds",
			iconUrl: icon,
		},
		{
			title: "Mezanine Floors",
			iconUrl: icon,
		},
		{
			title: "Prefabricated Homes",
			iconUrl: icon,
		},
		{
			title: "Tubular / Angular Structures",
			iconUrl: icon,
		},
		{
			title: "High tech High rise Goat Farm",
			iconUrl: icon,
		},
	];
	const listDataColumn2 = [
		{
			title: "Collapsible / Compound Gates",
			iconUrl: icon,
		},
		{
			title: "Metallic Home Doors",
			iconUrl: icon,
		},
		{
			title: "Steel Windows",
			iconUrl: icon,
		},
		{
			title: "Alluminium Sliding Windows",
			iconUrl: icon,
		},
	];
	const listDataColumn3 = [
		{
			title: "Steel Cupboards",
			iconUrl: icon,
		},
		{
			title: "Chairs",
			iconUrl: icon,
		},
		{
			title: "Cots",
			iconUrl: icon,
		},
		{
			title: "Window Grills",
			iconUrl: icon,
		},
		{
			title: "Staircase & Railings",
			iconUrl: icon,
		},
	];
	const listDataColumn4 = [
		{
			title: "Industrial / Textile / Pharmaceutical Trolleys",
			iconUrl: icon,
		},
		{
			title: "Rolling Shutters",
			iconUrl: icon,
		},
		{
			title: "Material Handling Systems",
			iconUrl: icon,
		},
		{
			title: "Centering Plates",
			iconUrl: icon,
		},
	];
	const itemObject = {
		fontSize: 18,
		iconColor: "yellow",
		iconBackground: "#e7ab25",
		iconRadius: "100%",
		fontWeight: "500",
		labelColor: "white",
		containerHeight: "24px",
		containerWidth: "24px",
		iconHeight: "12px",
		iconWidth: "12px",
		itemSpacing: "16px",
		itemMargin: "8px 0",
		blurBackground: "#e7ab2566",
	};
	const headerHeading = "Unique Engineerings - Steel Fabrication & Structures";
	const headerDescription =
		"Our company is engaged in manufacturing of steel structures and all kind of fabrication works, as per client drawings and specifications.\nWith a fully equipped workshop and latest machinery, our engineers provide innovative and reliable solutions.";
	return (
		<React.Fragment>
			<HeaderContainer>
				<Heading>{headerHeading}</Heading>
				{headerDescription.split("\n").map((str) => (
					<Desrciption>{str}</Desrciption>
				))}
				<VerticalSpacer />
				<VerticalSpacer />
				<Desrciption>Our wide range of solutions & products include:</Desrciption>
				<VerticalSpacer />
				<GridView
					desktopColumns={4}
					tabletColumns={2}
					phoneColumns={2}
					children={[
						<ListComponent
							margin={"28px 0"}
							listData={listDataColumn1}
							itemObject={itemObject}
						/>,
						<ListComponent
							margin={"28px 0"}
							listData={listDataColumn2}
							itemObject={itemObject}
						/>,
						<ListComponent
							margin={"28px 0"}
							listData={listDataColumn3}
							itemObject={itemObject}
						/>,
						<ListComponent
							margin={"28px 0"}
							listData={listDataColumn4}
							itemObject={itemObject}
						/>,
					]}
				/>
				<VerticalSpacer />
					<Desrciption>We also undertake all types of fabrications in Mild Steel (M.S.), Stainless Steel (S.S.) & Aluminium.</Desrciption>
					{/* <Desrciption>Service & repair of all types of rolling shutters are also undertaken.</Desrciption> */}
			</HeaderContainer>
			<GallerySection data={steelFabricationGalleryImages} />
			<ContactBanner title={"Let's fabricate your ideas together!"} />
		</React.Fragment>
	);
}

import styled from "styled-components";
import { NavLink } from "react-router-dom";
export const SideBarContainer = styled.aside`
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	padding: 4%;
	background-image: linear-gradient(to right, #225d65, #0d3336);
	right: ${({ isOpen }) => (isOpen ? "-0" : "-100%")};
	transition: 0.4s ease-in-out;
	opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
`;

export const SidebarLabel = styled.span`
	margin-left: 16px;
	color: black;
`;

export const SideBarMenu = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	${"" /* background:yellow; */}
	align-items:center;
	height: 50%;
`;

export const SideBarLink = styled(NavLink)`
	&& {
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		font-size: 28px;
		font-weight: 400;
		width: fit-content;
		transition: 0.2s ease-in-out;
		color: white;
		cursor: pointer;
		@media screen and (max-width: 420px) {
			font-size: 22px;
		}
	}
`;

export const IconButton = styled(NavLink)`
	& {
		padding: 2% 4%;
		width: fit-content;
		background-color: #e7ab25;
		font-size: 22px;
		display: flex;
		cursor: pointer;
		align-items: center;
		text-decoration: none;
		white-space: nowrap;
		@media screen and (max-width: 540px) {
			font-size: 22px;
		}
	}
`;
export const IconButtonText = styled.p`
	margin: auto;
	color: #000;
	font-weight: 700;
	font-size: inherit;
	text-transform: uppercase;
`;

import styled from "styled-components";

export const TestimonialContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 4% 4%;
	padding: 2% 4%;
	overflow: hidden;
	background-color: #ffffff;
	border-radius: 18px;
	@media screen and (max-width: 768px) {
		font-size: 32px;
		padding: 2% 8%;
	}
	@media screen and (max-width: 420px) {
	}
`;

export const TestimonialText = styled.div`
	font-size: 20px;
	color: #000000;
	text-align: left;
	line-height: 1.5;
	@media screen and (max-width: 768px) {
		font-size: 24px;
	}
	@media screen and (max-width: 420px) {
		font-size: 20px;
		font-weight: 500;
	} ;
`;

export const TestimonialAuthor = styled.div`
	font-size: 22px;
	margin-left: 0%;
	font-weight: 600;
	font-style: italic;
	color: #e9b237;
	@media screen and (max-width: 768px) {
		font-size: 24px;
		margin-left: 0%;
	}
	@media screen and (max-width: 420px) {
		font-size: 20px;
		margin-left: 0%;
	} ;
`;

export const VerticalSpacer = styled.span`
	padding: 16px 0 0 0;
`;

import {
	TestimonialContainer,
	TestimonialText,
	TestimonialAuthor,
	VerticalSpacer,
} from "./testimonialsStyle";
import quote from "../../../assets/quote.png";
const Testimonials = ({ item }) => {
	return (
		<TestimonialContainer key={item.author}>
			<div style={{width:"100%",marginBottom:"18px",marginRight:"8%"}}>
				<img src={quote} style={{ opacity: "0.17",maxWidth:"8%"}} alt="" />
			</div>
			<TestimonialText>"{item.testimonial}"</TestimonialText>
			<VerticalSpacer />
			<TestimonialAuthor>- {item.author}.</TestimonialAuthor>
		</TestimonialContainer>
	);
};

export default Testimonials;

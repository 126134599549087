import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const NavBarContainer = styled.div`
	background: ${({ bgColor }) => (bgColor ? bgColor : "#ffffffaa")};
	display: flex;
	position: ${({ position }) => (position ? position : "sticky")};
	background-image: linear-gradient(to right, #225d65, #0d3336);
	${"" /* background-image: linear-gradient(to right, #ffffff, #0d3336);  */}
	top: 0;
	left: 0;
	z-index: 999;
	right: 0;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 16px 32px;
	@media screen and (max-width: 768px) {
		padding: 16px 32px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	@media screen and (max-width: 540px) {
		padding: 16px 16px;
	}
`;
export const Logo = styled.img`
	width: 8%;
	margin-left: 18px;
	@media screen and (max-width: 1024px) {
		width: 12%;
		margin-left: 0px;
	}
	@media screen and (max-width: 768px) {
		width: 18%;
		margin-left: 0px;
	}
	@media screen and (max-width: 540px) {
		width: 24%;
		margin-left: 0px;
	}
`;
export const NavMenu = styled.div`
	display: flex;
	height: fit-content;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: flex-end;
	@media screen and (max-width: 768px) {
		display: none;
	}
`;
export const NavbarLink = styled(NavLink)`
	&& {
		color: white;
		text-decoration: none;
		font-weight: 600;
		cursor: pointer;
		font-size: 16px;
		margin: 12px 16px;
		&:hover {
			color: #e9b237;
		}
		@media screen and (max-width: 1024px) {
			font-size: 14px;
		}
	}
`;

export const MenuToggleIcon = styled.div`
	display: none;
	color: white;
	@media screen and (max-width: 768px) {
		display: flex;
		right: 0;
		font-size: 1.8rem;
		cursor: pointer;
	}
`;

export const NavLinkContainer = styled.div`
	height: 80px;
	display: flex;
	flex-direction: column;
	justify-content: initial;
	align-items: flex-end;
	padding: 0px 8px 0px 8px;
	@media screen and (max-width: 768px) {
		padding: 0px 4px;
	}
`;
export const DropdownContainer = styled.div`
	background: #cccccc;
	display: flex;
	border-radius: 4px;
	z-index: 999;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	@media screen and (max-width: 768px) {
		padding: 0px 4px;
	}
`;

export const SubNavLink = styled(NavLink)`
	& {
		color: black;
		height: inherit;
		margin: 0.2rem 1rem;
		cursor: pointer;
		text-decoration: none;
		${"" /* border-top: 3px solid #595959; */}
		&:hover {
			color: #2e8806;
		}
		&:active {
			color: #2e8806;
		}
	}
`;

export const IconButton = styled(NavLink)`
	& {
		margin-left: auto;
		padding: 8px 16px;
		margin: 1rem 1rem;
		background-color: #e7ab25;
		font-size: 14px;
		display: flex;
		cursor: pointer;
		align-items: center;
		text-decoration: none;
		white-space: nowrap;
	}
`;
export const IconButtonText = styled.p`
	margin: auto;
	color: #000;
	font-weight: 700;
	font-size: inherit;
	text-transform: uppercase;
`;

import React from "react";
import { FiX } from "react-icons/fi";
import ImageGalleryCarousel from "../galleryCarousel/galleryCarousel";
import { Image, ImageCarouselRoot, ImageContainer } from "./carouselViewStyle";

function FullScreenCard({ item }) {
	return (
		<ImageContainer>
			<Image src={item.imageLink} />
		</ImageContainer>
	);
}

export default function GalleryCarousel({data, onCarouselClose, carouselImageId }) {
	const children = [];
	data.map((item, index) => {
		children.push(<FullScreenCard item={item} key={index} />);
		return null;
	});
	return (
		<ImageCarouselRoot>
			<FiX
				style={{ zIndex:"999",position:"absolute",top:"24",fontSize: "36px", color: "white", marginRight: "24px" }}
				onClick={() => onCarouselClose()}
			/>
			<ImageGalleryCarousel
				children={children}
				fromSlide={carouselImageId}
				showNavigationArrows={true}
			/>
		</ImageCarouselRoot>
	);
}

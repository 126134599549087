import React from 'react';
import { MapContainer } from './mapComponentStyle';

export default function MapComponent(){
    return(
        <MapContainer>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15132.9575727083!2d73.8782421!3d18.518081!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3dcda33246e1c463!2sDecent%20Decor!5e0!3m2!1sen!2sin!4v1634981325543!5m2!1sen!2sin"
					width="100%"
					height="450"
					allowFullScreen=""
					loading="lazy"
					title="Map"
				></iframe>
			</MapContainer>
    );
}
import React,{useEffect} from "react";
import FormComponent from "../../../componentsRepo/formComponent/formComponent";
import MapComponent from "../../../componentsRepo/mapComponent/mapComponent";
import GridView from "./../../../componentsRepo/gridViewComponent/gridView";
import { ContactInfoParent } from "./contactUsStyle";
import CardComponent from "./../../../componentsRepo/contentCard/contentCard";

const addressObject = {
	cardPadding: "2% 0%",
	alignItems: "start",
	titleText: "Address:",
	titleTextSize: 24,
	titleFontWeight:"700",
	titleTextColor: "#000000",
	descriptionText: "S-65, Clover Center, 7, Moledina Rd, Pune - 411001, Maharashtra, India.",
	descriptionTextSize: 18,
	descriptionTextColor: "#000000",
};
const emailObject = {
	cardPadding: "2% 0%",
	alignItems: "start",
	titleText: "Email:",
	titleTextSize: 24,
	titleFontWeight:"700",
	titleTextColor: "#000000",
	descriptionText: "decentdecor52@gmail.com",
	descriptionTextSize: 18,
	descriptionTextColor: "#000000",
};
const phoneObject = {
	cardPadding: "2% 0%",
	alignItems: "start",
	titleText: "Phone:",
	titleTextSize: 24,
	titleFontWeight:"700",
	titleTextColor: "#000000",
	descriptionText: "+91 9822492452 \\ +91 20 26134082",
	descriptionTextSize: 18,
	descriptionTextColor: "#000000",
	itemSpacing: "0",
};

function CompanyInfo() {
	return (
		<ContactInfoParent>
			{/* <CardComponent {...titleObject} /> */}
			<CardComponent {...addressObject} />
			<CardComponent {...emailObject} />
			<CardComponent {...phoneObject} />
		</ContactInfoParent>
	);
}
export default function ContactUs() {
	useEffect(() => {
		window.scrollTo(0, 0);
	  }, []);
	return (
		<React.Fragment>
			<MapComponent />
			<GridView
				padding="2% 4%"
				desktopColumns={2}
				tabletColumns={2}
				phoneColumns={1}
				children={[<CompanyInfo />, <FormComponent />]}
			/>
		</React.Fragment>
	);
}

import styled from "styled-components";

export const GalleryCardContainer = styled.div`
	aspect-ratio:1/1;
	background: #f6f7f8;
`;

export const CardImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;
import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const ContactBannerContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 2% 4%;
	background: #f6ebe3;
	@media screen and (max-width: 768px) {
		align-items: center;
		${
			"" /* padding: 2% 4%;
		justify-content: flex-start; */
		}
	}
	@media screen and (max-width: 555px) {
		padding: 4% 4%;
		align-items: flex-start;
		flex-direction: column;
	}
`;
export const ContactText = styled.div`
	font-size: 38px;
	color: #0d3336;
	@media screen and (max-width: 786px) {
		font-size: 28px;
	}
	@media screen and (max-width: 555px) {
		font-size: 34px;
	} ;
`;
export const ContactButton = styled(NavLink)`
	& {
		background: #e9b237;
		text-decoration: none;
		padding: 1% 2%;
		font-size: 24px;
		color: #000000;
		font-weight:600;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		&:hover {
			color: black;
		}
		@media screen and (max-width: 786px) {
			font-size: 20px;
		}
		@media screen and (max-width: 555px) {
			font-size: 28px;
		}
	}
`;
export const ContactButtonText = styled.div``;
export const HorizontalSpacer = styled.div`
	margin: 0 8px;
`;
export const VerticalSpacer = styled.div`
	margin: 8px 0px;
`;

import styled from "styled-components";

export const BannerContainer = styled.div`
	width: 100%;
	aspect-ratio: 8/3;
	padding: 4% 8% 4% 8%;
	@media screen and (max-width: 768px) {
		padding: 4% 8% 8% 8%;
		aspect-ratio: 5/3;
	}
	@media screen and (max-width: 540px) {
		padding: 4% 6% 12% 6%;
		aspect-ratio: 3/3;
	}
	@media screen and (max-width: 420px) {
		padding: 4% 6% 12% 6%;
		aspect-ratio: 3/4;
	}
`;
export const BannerImage = styled.img`
	width: 100%;
	object-fit: cover;
	border-radius: 18px;
	height: 100%;
	object-position:${({ objectPosition }) => (objectPosition ? objectPosition : "0 100%")};
	box-shadow: 0 1px 4px rgba(7, 26, 27, 0.4), 0 0 18px rgba(7, 26, 27, 0.3);
	aspect-ratio: 8/3;
	@media screen and (max-width: 768px) {
		aspect-ratio: 5/3;
		border-radius: 24px;
	}
	@media screen and (max-width: 540px) {
		aspect-ratio: 3/3;
		border-radius: 24px;
	}
	@media screen and (max-width: 420px) {
		aspect-ratio: 3/4;
		border-radius: 24px;
	}
`;

import React,{useEffect,useState} from "react";
import {
	BrandNameText,
	ContactDetailsDescription,
	ContactDetailsParent,
	ContactDetailsTitle,
	CopyrightContainer,
	CopyrightText,
	FooterParent,
	HorizontalSpacer,
	Logo,
	NavigationLinks,
	Navigation,
	NavigationParent,
	NavigationTitle,
	SocialIcon,
	SocialMenuContainer,
	SocialParent,
	VerticalSpacer,
	CompanyNameText,
	DetailsSection,
	ContactDetailsLink,
	CompanyNameTitle
} from "./footerStyle";
import logo from "../../assets/logo.svg";
import {
	FaInstagram,
	FaFacebookSquare,
	FaLinkedin,
	FaYoutube,
} from "react-icons/fa";
import { getDeveloperDetails } from "../../services/firebaseService";

function SocialSection({data}) {
	const urlRedirect = (url) => {
		const newWindow = window.open(url, "_blank", "noopener,noreferrer");
		if (newWindow) newWindow.opener = null;
	};
	return (
		<SocialParent>
			<VerticalSpacer />
			<Logo src={logo} />
			<VerticalSpacer />
			<SocialMenuContainer>
				<SocialIcon>
					<FaInstagram />
				</SocialIcon>
				<HorizontalSpacer />
				<SocialIcon>
					<FaLinkedin />
				</SocialIcon>
				<HorizontalSpacer />
				<SocialIcon>
					<FaYoutube />
				</SocialIcon>
				<HorizontalSpacer />
				<SocialIcon>
					<FaFacebookSquare />
				</SocialIcon>
				<HorizontalSpacer />
			</SocialMenuContainer>
			<VerticalSpacer />
			<CopyrightContainer>
				<CopyrightText>Copyright © {new Date().getFullYear()}</CopyrightText>
				<br />
				<BrandNameText>Decent Decor®</BrandNameText>
				<VerticalSpacer />
			</CopyrightContainer>
				<VerticalSpacer />
				<CompanyNameTitle >Designed & Developed by:</CompanyNameTitle>
				<CompanyNameText onClick={()=>urlRedirect(data[0].link)}>{data[0]!==undefined?data[0].label:" "}</CompanyNameText>
		</SocialParent>
	);
}

function NavigationSection({ data }) {
	return (
		<>
			{data.map((item, index) => {
				return (
					<NavigationParent key={item.title}>
						<NavigationTitle>{item.title}</NavigationTitle>
						{/* <VerticalSpacer /> */}
						{item.links.map((subItems, index) => {
							return (
								<div key={subItems.title}>
									<NavigationLinks to={subItems.to}>
										{subItems.title}
									</NavigationLinks>
									<VerticalSpacer />
								</div>
							);
						})}
						<VerticalSpacer />
					</NavigationParent>
				);
			})}
		</>
	);
}
function ContactDetails() {
	return (
		<ContactDetailsParent>
			<div>
				<ContactDetailsTitle>Contact:</ContactDetailsTitle>
				<ContactDetailsDescription>
					<ContactDetailsLink href="tel:+91 9822492452">
						+91 9822492452
					</ContactDetailsLink>
				</ContactDetailsDescription>
				<ContactDetailsDescription>
					<ContactDetailsLink href="tel:+91 20 26134082">
						+91 20 26134082
					</ContactDetailsLink>
				</ContactDetailsDescription>
				<ContactDetailsDescription>
					<ContactDetailsLink href="mailto:decentdecor52@gmail.com">
						decentdecor52@gmail.com
					</ContactDetailsLink>
				</ContactDetailsDescription>
			</div>
			<VerticalSpacer />
			<VerticalSpacer />
			<div>
				<ContactDetailsTitle>Address:</ContactDetailsTitle>
				<ContactDetailsDescription>
					DECENT DECOR
					<br />
					S-65, Clover Center,
					<br />
					7, Moledina Rd, 
					<br />
					Pune-411001
					<br />
					India.
				</ContactDetailsDescription>
			</div>
			<VerticalSpacer />
		</ContactDetailsParent>
	);
}
export default function Footer() {
	const data = [
		{
			title: "Services",
			links: [
				{
					title: "Interior Decor",
					to: "/interior",
				},
				{
					title: "Steel Fabrication",
					to: "/steel",
				},
				{
					title: "Solar Panels",
					to: "/solarpanel",
				},
			],
		},
	];
	const [developerData, setDeveloperDataState] = useState([]);
	const setDeveloperData = async () => {
		const data  = await getDeveloperDetails();
		setDeveloperDataState(data);
		
	};
	useEffect(() => {
		setDeveloperData();
	}, []);
	return (
		<FooterParent>
			<SocialSection data={developerData}/>
			<DetailsSection>
				<Navigation>
					<NavigationSection data={data} />
				</Navigation>
				<ContactDetails />
			</DetailsSection>
		</FooterParent>
	);
}

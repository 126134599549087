import styled from "styled-components";
import { Button } from "react-bootstrap";

export const CarouselRoot = styled.div`
	flexgrow: 1;
	position: relative;
	background-color: ${({ backgroundColor }) =>
		backgroundColor ? backgroundColor : "#ffffff"};
	display: flex;
	margin: ${({ margin }) => (margin ? margin : "0px")};
	flex-direction: row;
	align-items: center;
`;
export const ButtonRight = styled(Button)`
	& {
		color:#E9B237;
		height: 50px;
		padding: 12px;
		font-size: 36px;
		margin-right: 8;
		background-color: #22434311;
		border:none;
		display:flex;
		align-items:center;
		position: absolute;
		justify-content: center;
		scroll-snap-align: center;
		right: ${({ right }) => right};
		left: ${({ left }) => left};
		&:hover{
			background:transparent;
		}
		&:disabled{
			background:transparent;
		}
		&:focus{
			background:transparent;
			border:none;
		}
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
`;
export const StepperContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: fit-content;
	align-self: center;
	position: absolute;
	bottom: 5%;
	right: 0;
	left: 0;
	margin: 0 auto;
	${"" /* background-color:red; */}
	z-index: 10;
`;
export const StepperStep = styled.div`
	margin: 0px 8px;          
    height: 4px;
    width: 64px;
    background-color: ${({ selected, carouselIndicatorColor }) =>
			selected
				? carouselIndicatorColor
					? carouselIndicatorColor
					: "#000000"
				: "#FFFFFF53"};        
    @media screen and (max-width:768px){
    width: 24px;
    margin: 0px 4px;
    },
`;

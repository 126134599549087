import styled from "styled-components";

export const CardContainer = styled.div`
	background: ${({ bgColor }) => (bgColor ? bgColor : "#ffffff")};
	padding: ${({ cardPadding }) => (cardPadding ? cardPadding : 0)};
	background-image: url(${({ bImg }) => bImg});
	background-image: ${({ gradient }) => gradient};
	display: flex;
	flex: 1;
	flex-basis: ${({ desktopWeight }) => (desktopWeight ? desktopWeight : "50%")};
	flex-direction: column;
	justify-content: flex-start;
	align-items: ${({ alignItems }) => (alignItems ? alignItems : "flex-start")};
	width: auto;
	box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : "none")};
	&:hover {
		box-shadow: ${({ hoverStyle }) => (hoverStyle ? hoverStyle : "none")};
	}
	@media screen and (max-width: 768px) {
		flex-basis: ${({ tabletWeight }) => (tabletWeight ? tabletWeight : "50%")};
	}
	@media screen and (max-width: 420px) {
		flex-basis: ${({ phoneWeight }) => (phoneWeight ? phoneWeight : "100%")};
	}
`;

export const ImageConatiner = styled.img`
	width: ${({ imageWidth }) => (imageWidth ? imageWidth : "100%")};
	height: ${({ imageHeight }) => (imageHeight ? imageHeight : "100%")};
	@media screen and (max-width: 960px) {
		max-width: 70%;
	}
`;

export const TagContainer = styled.div`
	font-size: ${({ tagTextSize }) =>
		tagTextSize ? tagTextSize + "px" : "12px"};
	font-weight: ${({ tagFontWeight }) =>
		tagFontWeight ? tagFontWeight : "200"};
	color: ${({ tagTextColor }) => (tagTextColor ? tagTextColor : "#000000")};
	@media screen and (max-width: 1024px) {
		font-size: ${({ tagTextSize }) =>
			tagTextSize ? tagTextSize * 0.85 + "px" : "12px"};
	}
	@media screen and (max-width: 768px) {
		font-size: ${({ tagTextSize }) =>
			tagTextSize ? tagTextSize * 0.8 + "px" : "12px"};
	}
	@media screen and (max-width: 540px) {
		font-size: ${({ tagTextSize }) =>
			tagTextSize ? tagTextSize * 0.7 + "px" : "12px"};
	}
`;

export const TitleContainer = styled.div`
	font-size: ${({ titleTextSize }) =>
		titleTextSize ? titleTextSize + "px" : "12px"};
	font-weight: ${({ titleFontWeight }) =>
		titleFontWeight ? titleFontWeight : "500"};
	color: ${({ titleTextColor }) =>
		titleTextColor ? titleTextColor : "#000000"};
	${"" /* background: red; */}
	@media screen and (max-width: 1024px) {
		font-size: ${({ titleTextSize }) =>
			titleTextSize ? titleTextSize * 0.85 + "px" : "12px"};
	}
	@media screen and (max-width: 768px) {
		font-size: ${({ titleTextSize }) =>
			titleTextSize ? titleTextSize * 0.8 + "px" : "12px"};
	}
	@media screen and (max-width: 540px) {
		font-size: ${({ titleTextSize }) =>
			titleTextSize ? titleTextSize * 0.7 + "px" : "12px"};
	}
`;

export const DescriptionContainer = styled.p`
	font-size: ${({ descriptionTextSize }) =>
		descriptionTextSize ? descriptionTextSize + "px" : "12px"};
	font-weight: ${({ descriptionFontWeight }) =>
		descriptionFontWeight ? descriptionFontWeight : "400"};
	color: ${({ descriptionTextColor }) =>
		descriptionTextColor ? descriptionTextColor : "#000000"};
	${"" /* background: blue; */}
	@media screen and (max-width: 1024px) {
		font-size: ${({ descriptionTextSize }) =>
			descriptionTextSize ? descriptionTextSize * 0.9 + "px" : "12px"};
	}
	@media screen and (max-width: 768px) {
		font-size: ${({ descriptionTextSize }) =>
			descriptionTextSize ? descriptionTextSize * 0.8 + "px" : "12px"};
	}
	@media screen and (max-width: 540px) {
		font-size: ${({ descriptionTextSize }) =>
			descriptionTextSize ? descriptionTextSize * 0.8 + "px" : "12px"};
	}
`;

export const Button = styled.div`
	color: ${({ buttonTextColor }) =>
		buttonTextColor ? buttonTextColor : "#ffffff"};
	padding: ${({ buttonPadding }) => (buttonPadding ? buttonPadding : "8px")};
	background-color: ${({ buttonFillColor }) =>
		buttonFillColor ? buttonFillColor : "#000000"};
	font-size: ${({ buttonTextSize }) =>
		buttonTextSize ? buttonTextSize + "px" : "12px"};
	font-weight: ${({ buttonFontWeight }) =>
		buttonFontWeight ? buttonFontWeight : "200"};
	display: flex;
	border-radius: ${({ buttonRadius }) => (buttonRadius ? buttonRadius : "4px")};
	border: ${({ buttonBorderStyle }) =>
		buttonBorderStyle ? buttonBorderStyle : "2px solid #ffffff"};
	cursor: pointer;
	@media screen and (max-width: 1024px) {
		font-size: ${({ buttonTextSize }) =>
			buttonTextSize ? buttonTextSize * 0.8 + "px" : "12px"};
	}
	@media screen and (max-width: 768px) {
		font-size: ${({ buttonTextSize }) =>
			buttonTextSize ? buttonTextSize * 0.8 + "px" : "12px"};
	}
	@media screen and (max-width: 540px) {
		font-size: ${({ buttonTextSize }) =>
			buttonTextSize ? buttonTextSize * 0.75 + "px" : "12px"};
	}
	${
		"" /* &:hover {
        background-color: #e7ab25;
    }, */
	}
`;

export const VerticalSpacer = styled.span`
	margin: ${({ spacerHeight }) => (spacerHeight ? spacerHeight : "0px")} 0 0 0;
`;

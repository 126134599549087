import React, { useState } from "react";
import SideBar from "./sideBar";
import NavBar from "./navBar";
import data from "../../data/navbarData.json";

export default function AppBar({position}) {
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => {
		setIsOpen(!isOpen);
	};
	return (
		<>
			<NavBar links={data} toggle={toggle} isOpen={isOpen} position={position}/>
			<SideBar data={data} isOpen={isOpen} toggle={toggle} />
		</>
	);
}

import styled from "styled-components";

export const ParentContainer = styled.div`
	${
		"" /* display:flex;
	 flex-wrap:wrap; */
	}
	width:100%;
	padding: ${({ padding }) => (padding ? padding : "0")};
	display: grid;
	direction: ${({ direction }) => (direction ? direction : "ltr")};
	grid-template-columns: repeat(
		auto-fit,
		minmax(
			${({ desktopColumns }) => (desktopColumns ? desktopColumns : "33%")},
			1fr
		)
	);

	@media screen and (max-width: 768px) {
		grid-template-columns: repeat(
			auto-fit,
			minmax(
				${({ tabletColumns }) => (tabletColumns ? tabletColumns : "50%")},
				1fr
			)
		);
	}
	@media screen and (max-width: 540px) {
		${"" /* flex-direction:column; */}
		grid-template-columns: repeat(auto-fit, minmax(${({ phoneColumns }) =>
			phoneColumns ? phoneColumns : "100%"}, 1fr));
	}
`;

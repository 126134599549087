import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
const firebaseConfig = {
	apiKey: "AIzaSyAxB-Oja8d9o7OsQwu2KdpRvKbB00PGXgA",
	authDomain: "decent-decor-d8832.firebaseapp.com",
	projectId: "decent-decor-d8832",
	storageBucket: "decent-decor-d8832.appspot.com",
	messagingSenderId: "549011466054",
	appId: "1:549011466054:web:7895fcff01116e91042747",
	measurementId: "G-G7FL3TMX58",
};
firebase.initializeApp(firebaseConfig);
export default firebase;

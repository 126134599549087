import React from "react";
import Banner from "./bannerComponent/banner";
import Carousel from "../../componentsRepo/carousel/carousel";
export default function BannerSection({ data }) {
    const children = [];
	data.map((item, index) => {
		children.push(<Banner item={item} key={index} />);
		return null;
	});
	return (
		<div style={{backgroundImage: "linear-gradient(to right,#225d65, #0d3336)"}}>
		<Carousel
			children={children}
			showNavigationArrows={true}
			backgroundColor={"#DEF2F100"}
			carouselIndicatorColor={"#E9B237"}
			carouselIndicator={true}
		/>
		</div>
	);
}

import React, { useRef } from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import emailjs from "emailjs-com";
import { FormContainer, SubmitButton } from "./formComponentStyle";
import { toast } from "react-toastify";

export default function FormComponent() {
	const formRef = useRef();
	const onSubmit = (e) => {
		e.preventDefault();
		if (
			formRef.current.email.value === "" ||
			formRef.current.subject.value === "" ||
			formRef.current.message.value === ""
		) {
			toast.error("Please Fill All The Fields", {
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				theme:"colored"
			});
		} else {
			emailjs
				.sendForm(
					"service_m08toys",
					"template_leddeon",
					formRef.current,
					"user_WQiBv411zusJfB9uqVC0i"
				)
				.then(
					(result) => {
						console.log(result.text);
						toast.success("Message Sent", {
							autoClose: 2000,
							hideProgressBar: true,
							closeOnClick: true,
							theme:"colored"
						});
					},
					(error) => {
						console.log(error.text);
						toast.error("Message Sending Failed", {
							autoClose: 2000,
							hideProgressBar: true,
							closeOnClick: true,
							theme:"colored"
						});
					}
				);
		}
		formRef.current.reset();
	};
	return (
		<FormContainer ref={formRef} onSubmit={onSubmit}>
			<Form.Group key={1} className="mb-3" controlId="formBasicEmail">
				<FloatingLabel
					controlId="floatingInput"
					label="Email address"
					className="mb-3"
				>
					<Form.Control name="email" type="email" placeholder="EMAIL" />
				</FloatingLabel>
			</Form.Group>
			<Form.Group key={2} className="mb-3" controlId="formBasicSubject">
				<FloatingLabel
					controlId="floatingInputSubject"
					label="Subject"
					className="mb-3"
				>
					<Form.Control name="subject" type="text" placeholder="SUBJECT" />
				</FloatingLabel>
			</Form.Group>
			<Form.Group key={3} className="mb-3" controlId="formBasicMessage">
				<FloatingLabel
					controlId="floatingInputMessage"
					label="Message"
					className="mb-3"
				>
					<Form.Control
						as="textarea"
						name="message"
						style={{ height: "200px" }}
						placeholder="MESSAGE"
					/>
				</FloatingLabel>
			</Form.Group>
			<SubmitButton onClick={onSubmit} type="submit">Submit</SubmitButton>
		</FormContainer>
	);
}
